import AddIcon from '@mui/icons-material/Add';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Button, Container, Stack, Tab, Typography } from '@mui/material';
import { capitalCase } from 'change-case';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Label from 'components/Label';
import Page from 'components/Page';
import useSettings from 'hooks/useSettings';
import { capitalize } from 'lodash';
import { createContext, useEffect, useReducer } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { PATHS } from 'routes/paths';
import axios from 'utils/axios';
import { fTimestamp } from 'utils/formatTime';

export const CUSTOMER_TABS = [
  { value: 'info', label: 'Info' },
  { value: 'users', label: 'Users' },
  { value: 'transactions', label: 'Transactions' },
  { value: 'fees', label: 'Fees' },
  { value: 'orders', label: 'Orders' },
  { value: 'reconciliations', label: 'Reconciliations' },
  { value: 'settings', label: 'Settings' },
];

const initialState = {
  currentTab: 'info',
  loading: true,
  error: null,
  data: { name: null, category: null },
  users: {
    loading: true,
    error: null,
    data: [],
    stats: {},
    totalRows: 0,
  },
  transactions: {
    loading: true,
    error: null,
    data: [],
    stats: {},
    totalRows: 0,
  },
  fees: {
    loading: true,
    error: null,
    data: [],
    totalRows: 0,
  },
  orders: {
    loading: true,
    error: null,
    data: [],
    stats: {},
    totalRows: 0,
  },
  reconciliations: {
    loading: true,
    error: null,
    data: [],
    stats: {},
    totalRows: 0,
  },
};

const handlers = {
  CUSTOMER_FETCH: (state) => ({ ...state, loading: true, currentTab: 'info' }),
  CUSTOMER_SUCCESS: (state, { payload: { info } }) => ({
    ...state,
    loading: false,
    data: { ...info },
    error: null,
  }),
  CUSTOMER_FAILED: (state, { payload: { error } }) => ({ ...state, loading: false, error }),
  ORDERS_FETCH: (state) => ({
    ...state,
    orders: {
      ...state.orders,
      loading: true,
    },
    currentTab: 'orders',
  }),
  ORDERS_SUCCESS: (state, { payload: { orders, stats } }) => ({
    ...state,
    orders: { ...state.orders, loading: false, data: orders.items, totalRows: orders.total, stats },
  }),
  ORDERS_FAILED: (state, { payload: { error } }) => ({ ...state, loading: false, error }),
  USERS_FETCH: (state) => ({ ...state, users: { ...state.users, loading: true }, currentTab: 'users' }),
  USERS_SUCCESS: (state, { payload: { users, stats } }) => ({
    ...state,
    users: { ...state.users, loading: false, data: users.items, totalRows: users.total, stats },
  }),
  USERS_FAILED: (state, { payload: { error } }) => ({ ...state, loading: false, error }),
  TRANSACTIONS_FETCH: (state) => ({
    ...state,
    transactions: { ...state.transactions, loading: true },
    currentTab: 'transactions',
  }),
  TRANSACTIONS_SUCCESS: (state, { payload: { transactions, stats } }) => ({
    ...state,
    transactions: {
      ...state.transactions,
      loading: false,
      data: transactions.items,
      totalRows: transactions.total,
      stats,
    },
  }),
  TRANSITIONS_FAILED: (state, { payload: { error } }) => ({ ...state, loading: false, error }),
  FEES_FETCH: (state) => ({ ...state, fees: { ...state.fees, loading: true }, currentTab: 'fees' }),
  FEES_SUCCESS: (state, { payload: { fees } }) => ({
    ...state,
    fees: { ...state.fees, loading: false, data: fees.items, totalRows: fees.total },
  }),
  FEES_FAILED: (state, { payload: { error } }) => ({ ...state, loading: false, error }),
  RECONCILIATIONS_FETCH: (state) => ({
    ...state,
    reconciliations: { ...state.reconciliations, loading: true },
    currentTab: 'reconciliations',
  }),
  RECONCILIATIONS_SUCCESS: (state, { payload: { reconciliations, stats } }) => ({
    ...state,
    reconciliations: {
      ...state.reconciliations,
      loading: false,
      data: reconciliations.items,
      totalRows: reconciliations.total,
      stats,
    },
  }),
  RECONCILIATIONS_FAILED: (state, { payload: { error } }) => ({ ...state, loading: false, error }),
  CHANGE_TAB: (state, { payload: { tab } }) => ({ ...state, currentTab: tab }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const CustomersContext = createContext({
  ...initialState,
  getCustomerInfo: () => Promise.resolve(),
  getCustomerOrders: () => Promise.resolve(),
  getCustomerUsers: () => Promise.resolve(),
  getCustomerTransactions: () => Promise.resolve(),
  getCustomerFees: () => Promise.resolve(),
  getCustomerReconciliations: () => Promise.resolve(),
});

function CustomersProvider() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { themeStretch } = useSettings();

  const location = useLocation();
  const navigate = useNavigate();
  const { customerId } = useParams();

  useEffect(() => {
    getCustomerInfo(customerId);
  }, [customerId]);

  const getCustomerInfo = async (customerId) => {
    dispatch({ type: 'CUSTOMER_FETCH' });

    try {
      const response = await axios.get(`/customers/${customerId}`);
      const info = response.data;
      dispatch({ type: 'CUSTOMER_SUCCESS', payload: { info } });
    } catch (error) {
      dispatch({ type: 'CUSTOMER_FAILED', payload: { error } });
    }
  };

  const getCustomerUsers = async (customerId, page, size, status) => {
    dispatch({ type: 'USERS_FETCH' });

    try {
      const response = await axios.get(`/customers/${customerId}/users`, { params: { page, size, status } });
      const users = response.data;
      const responseStats = await axios.get(`/stats/overview/users/${customerId}`);
      const stats = responseStats.data;
      dispatch({ type: 'USERS_SUCCESS', payload: { users, stats } });
    } catch (error) {
      dispatch({ type: 'USERS_FAILED', payload: { error } });
    }
  };

  const getCustomerTransactions = async (customerId, page, size, status) => {
    dispatch({ type: 'TRANSACTIONS_FETCH' });

    try {
      const response = await axios.get(`/customers/${customerId}/bank_transactions`, {
        params: { page, size, status },
      });
      const transactions = response.data;
      const responseStats = await axios.get(`/stats/overview/transactions/${customerId}`);
      const stats = responseStats.data;
      dispatch({ type: 'TRANSACTIONS_SUCCESS', payload: { transactions, stats } });
    } catch (error) {
      dispatch({ type: 'TRANSACTIONS_FAILED', payload: { error } });
    }
  };

  const getCustomerFees = async (customerId, page, size) => {
    dispatch({ type: 'FEES_FETCH' });

    try {
      const response = await axios.get(`/customers/${customerId}/fees`, { params: { page, size } });
      const fees = response.data;
      dispatch({ type: 'FEES_SUCCESS', payload: { fees } });
    } catch (error) {
      dispatch({ type: 'FEES_FAILED', payload: { error } });
    }
  };

  // eslint-disable-next-line camelcase
  const getCustomerOrders = async (customerId, order_type, status, start_date, end_date, page, size) => {
    dispatch({ type: 'ORDERS_FETCH' });

    try {
      const response = await axios.get(`/customers/${customerId}/orders`, {
        // eslint-disable-next-line camelcase
        params: { order_type, status, start_date: fTimestamp(start_date), end_date: fTimestamp(end_date), page, size },
      });
      const orders = response.data;
      const responseStats = await axios.get(`/stats/overview/orders`, {
        params: { customer_id: customerId, start_date: fTimestamp(start_date), end_date: fTimestamp(end_date) },
      });

      const stats = responseStats.data;
      dispatch({ type: 'ORDERS_SUCCESS', payload: { orders, stats } });
    } catch (error) {
      dispatch({ type: 'ORDERS_FAILED', payload: { error } });
    }
  };

  const getCustomerReconciliations = async (customerId, filter, filterStartDate, filterEndDate, page, size) => {
    dispatch({ type: 'RECONCILIATIONS_FETCH' });

    try {
      const response = await axios.get(`/customers/${customerId}/reconciliations`, {
        params: {
          status: filter,
          start_date: fTimestamp(filterStartDate),
          end_date: fTimestamp(filterEndDate),
          page,
          size,
        },
      });
      const reconciliations = response.data;
      const responseStats = await axios.get(`/stats/overview/reconciliations/${customerId}`, {
        params: { start_date: fTimestamp(filterStartDate), end_date: fTimestamp(filterEndDate) },
      });
      const stats = responseStats.data;
      dispatch({ type: 'RECONCILIATIONS_SUCCESS', payload: { reconciliations, stats } });
    } catch (error) {
      dispatch({ type: 'RECONCILIATIONS_FAILED', payload: { error } });
    }
  };

  const changeTab = (tab) => {
    dispatch({ type: 'CHANGE_TAB', payload: { tab } });
  };

  const handleChangeTab = (e, tab) => {
    changeTab(tab);
    const currentPath = location.pathname;
    const pathItems = currentPath.split('/');

    pathItems[pathItems.length - 1] = tab;
    navigate(PATHS.customers[tab](customerId));
  };

  const renderTabAction = () => {
    let actionUrl = '';
    switch (state.currentTab) {
      case 'transactions':
        actionUrl = `/customers/${customerId}/transactions/new`;
        break;
      case 'users':
        actionUrl = `/customers/${customerId}/users/new`;
        break;
      case 'fees':
        actionUrl = `/customers/${customerId}/fees/new`;
        break;
      default:
        actionUrl = null;
    }

    return (
      actionUrl && (
        <Button component={RouterLink} variant="contained" color="warning" to={actionUrl} endIcon={<AddIcon />}>
          New
        </Button>
      )
    );
  };

  return (
    <CustomersContext.Provider
      value={{
        ...state,
        getCustomerInfo,
        getCustomerOrders,
        getCustomerUsers,
        getCustomerTransactions,
        getCustomerFees,
        getCustomerReconciliations,
        changeTab,
      }}
    >
      <Page title="Customers">
        <Container maxWidth={themeStretch ? false : 'lg'}>
          <HeaderBreadcrumbs
            heading={
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="h4" gutterBottom>
                  {state.data.name}
                </Typography>
                <Label color="info">{capitalize(state.data.custody)}</Label>
              </Stack>
            }
            links={[
              { name: 'General', href: PATHS.general },
              { name: 'Customers', href: PATHS.customers.list },
              { name: 'Detail' },
            ]}
            action={renderTabAction()}
          />
          <TabContext value={state.currentTab || 'info'}>
            <TabList onChange={handleChangeTab}>
              {CUSTOMER_TABS.map((tab) => (
                <Tab key={tab.value} label={capitalCase(tab.label)} value={tab.value} />
              ))}
            </TabList>
            <TabPanel
              value={state.currentTab || 'info'}
              sx={{
                marginTop: 4,
              }}
            >
              <Outlet />
            </TabPanel>
          </TabContext>
        </Container>
      </Page>
    </CustomersContext.Provider>
  );
}

export { CustomersContext, CustomersProvider };
