import { Box, Typography, Stack, Divider } from '@mui/material';
import useSettings from 'hooks/useSettings';
import { capitalize } from 'lodash';
import { fDate } from 'utils/formatTime';

export default function TestModeBanner() {
  const { themeData } = useSettings();

  const { testMode, orderWorker, currentDate } = themeData || {};

  if (!testMode) return null;

  return (
    <Box
      bgcolor="error.darker"
      sx={{
        width: '100%',
        height: '40px',
        zIndex: 9999,
        position: 'fixed',
        bottom: 0,
        borderTop: 1,
        borderColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        px: 2,
      }}
    >
      <Stack direction="row" spacing={2}>
        <Typography variant="body2" color="error.contrastText">
          Running in Test Mode.
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography variant="body2" color="error.contrastText">
          Worker: {capitalize(orderWorker) || 'Unknown'}
        </Typography>
        <Divider orientation="vertical" flexItem />

        <Typography variant="body2" color="error.contrastText">
          Date: {fDate(currentDate) || 'Unknown'}
        </Typography>
      </Stack>
    </Box>
  );
}
