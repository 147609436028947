import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Switch, FormControlLabel } from '@mui/material';

const RHFSwitch = forwardRef(({ name, onChange, ...other }, ref) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          control={
            <Switch
              {...field}
              checked={field.value || false}
              onChange={(event) => {
                field.onChange(event.target.checked);
                if (onChange) {
                  onChange(event, event.target.checked);
                }
              }}
              inputRef={ref}
            />
          }
          {...other}
        />
      )}
    />
  );
});

RHFSwitch.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
};

export default RHFSwitch;
