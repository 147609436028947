import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormControl, MenuItem, Select, Stack } from '@mui/material';
import { FormProvider, RHFSwitch, RHFTextField } from 'components/hook-form';
import useApiSettings from 'hooks/useApiSettings';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import axios from 'utils/axios';
import * as Yup from 'yup';

const ORDER_WORKER_OPTIONS = ['api', 'kafka'];

export default function SettingAPI() {
  const { apiSettings } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const { set } = useApiSettings();

  const TestSettingSchema = Yup.object().shape({
    test_mode: Yup.boolean().required('Test mode is required'),
    order_worker: Yup.string().oneOf(ORDER_WORKER_OPTIONS).required('Order worker is required'),
    kafka_running: Yup.string().oneOf(['on', 'off']),
    error_rate: Yup.number().required('Error rate is required'),
    current_date: Yup.date().required('Current date is required'),
  });

  const defaultValues = {
    test_mode: apiSettings?.test_mode,
    order_worker: apiSettings?.order_worker,
    kafka_running: apiSettings?.kafka_running,
    error_rate: apiSettings?.error_rate,
    current_date: apiSettings?.current_date,
  };

  const methods = useForm({
    resolver: yupResolver(TestSettingSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const toggleTestMode = (checked) => {
    set({ ...values, test_mode: checked });
  };

  const onSubmit = async (data) => {
    await set(data);
  };

  const handleGenerateReconcilitiations = async () => {
    try {
      await axios.post(`/reconciliations/generate`);
      enqueueSnackbar('Reconciliations generated successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.message || 'Failed to generate reconciliations', { variant: 'error' });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <RHFTextField type="date" label="Current Date" name="current_date" disabled={isSubmitting} fullWidth />
        <FormControl fullWidth margin="normal" size="small">
          <Controller
            name="order_worker"
            control={control}
            defaultValue={apiSettings?.order_worker}
            render={({ field }) => (
              <Select
                {...field}
                labelId="order-worker-label"
                id="order_worker"
                label="Order Worker"
                value={field.value || ''}
                onChange={(e) => field.onChange(e.target.value)}
                error={!!methods.formState.errors.order_worker}
                sx={{
                  '& .MuiSelect-select': {
                    color: field.value ? 'inherit' : '#adadad',
                  },
                }}
              >
                <MenuItem selected value="api" size="small">
                  API
                </MenuItem>
                <MenuItem value="kafka" disabled={apiSettings?.kafka_running === 'off'} size="small">
                  Kafka
                </MenuItem>
              </Select>
            )}
          />
        </FormControl>
        <RHFTextField
          type="number"
          label="Error Rate"
          name="error_rate"
          disabled={isSubmitting}
          fullWidth
          // add % percentage to end
          InputProps={{
            endAdornment: '%',
          }}
        />
        <RHFSwitch
          edge="start"
          label="Test Mode"
          name="test_mode"
          disabled={isSubmitting}
          onChange={(event, checked) => {
            toggleTestMode(checked);
          }}
        />
        <Button
          fullWidth
          variant="outlined"
          disabled={isSubmitting}
          sx={{ mt: 2 }}
          onClick={handleGenerateReconcilitiations}
        >
          Generate Reconcilitiations
        </Button>
      </Stack>
    </FormProvider>
  );
}
