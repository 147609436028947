import MotionLazyContainer from 'components/animate/MotionLazyContainer';
import { ChartStyle } from 'components/chart';
import NotistackProvider from 'components/NotistackProvider';
import { ProgressBarStyle } from 'components/ProgressBar';
import ScrollToTop from 'components/ScrollToTop';
import ThemeSettings from 'components/settings';
import Router from 'routes';
import ThemeProvider from 'theme';
import TestModeBanner from 'components/TestModeBanner';

export default function App() {
  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <NotistackProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <TestModeBanner />
            <Router />
          </NotistackProvider>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
