import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

RHFAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func,
  isOptionEqualToValue: PropTypes.func,
  isDisabledAutocomplete: PropTypes.bool,
};

export default function RHFAutocomplete({
  name,
  options,
  getOptionLabel,
  isOptionEqualToValue,
  isDisabledAutocomplete,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          sx={{ width: '100%' }}
          options={options}
          getOptionLabel={getOptionLabel}
          disabled={isDisabledAutocomplete}
          isOptionEqualToValue={isOptionEqualToValue}
          renderInput={(params) => (
            <TextField {...params} fullWidth error={!!error} helperText={error?.message} {...other} />
          )}
          onChange={(event, value) => field.onChange(value)}
        />
      )}
    />
  );
}
