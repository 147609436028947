import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { Switch, FormControlLabel } from '@mui/material';

const RHFSwitch = forwardRef(({ name, ...other }, ref) => {
  const { control } = useFormContext();

  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={({ field }) => <Switch {...field} checked={field.value} ref={ref} />}
        />
      }
      {...other}
    />
  );
});

RHFSwitch.propTypes = {
  name: PropTypes.string.isRequired,
};

export default RHFSwitch;
