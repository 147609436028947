import { useAuth0 } from '@auth0/auth0-react';
import useApiSettings from 'hooks/useApiSettings';
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
import axios from 'utils/axios';
import { CLIENT_HOST } from '../config';

const initialUser = {
  id: '',
  email: '',
  name: '',
  status: '',
  role: '',
  displayName: '',
  is_staff: false,
  company: {
    id: '',
    name: '',
    isFullyDisclosed: false,
  },
};

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: initialUser,
  isCustomer: false,
  auth0User: null,
  accessToken: '',
  authError: null,
  customer: null,
  layout: 'admin',
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, accessToken } = action.payload;
    return { ...state, isAuthenticated, isInitialized: true, auth0User: user, accessToken };
  },
  LOGIN: (state, action) => {
    const { user, accessToken } = action.payload;
    return { ...state, isAuthenticated: true, auth0User: user, accessToken };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: initialUser,
    auth0User: null,
    accessToken: '',
  }),
  ME_SUCCESS: (state, action) => {
    const { user, customer } = action.payload;

    const isCustomer = !!customer;

    return {
      ...state,
      user,
      customer,
      authError: null,
      isCustomer,
      layout: isCustomer ? 'customer' : 'admin',
    };
  },
  ME_FAILED: (state, action) => ({ ...state, user: initialUser, authError: action.payload.error }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'auth0',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setPassword: () => Promise.resolve(),
});

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { isAuthenticated, user, isLoading, loginWithPopup, logout, getAccessTokenSilently } = useAuth0();

  const { settings: apiSettings } = useApiSettings();

  useEffect(() => {
    const initialize = async () => {
      if (!isLoading) {
        try {
          const accessToken = await getAccessTokenSilently();

          await fetchMe(accessToken);

          dispatch({
            type: 'INITIALIZE',
            payload: { isAuthenticated, user, accessToken },
          });
        } catch (err) {
          dispatch({
            type: 'INITIALIZE',
            payload: { isAuthenticated: false, user: null, accessToken: '' },
          });
        }
      }
    };
    initialize();
  }, [isLoading, isAuthenticated, user, getAccessTokenSilently]);

  const login = async () => {
    try {
      await loginWithPopup();
      const accessToken = await getAccessTokenSilently();
      await fetchMe(accessToken);

      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          isAuthenticated,
          accessToken,
        },
      });
    } catch (err) {
      console.error('Error during Auth0 login:', err);
    }
  };

  const logoutHandler = () => {
    if (process.env.REACT_APP_NODE_ENV === 'development') {
      logout({ logoutParams: { returnTo: CLIENT_HOST } });
    } else {
      logout();
    }
    dispatch({ type: 'LOGOUT' });
  };

  // TODO: Refactor this
  const fetchMe = async (accessToken) => {
    try {
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

      const { data: me } = await axios.get('/users/me', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      let customerData = null;

      if (me?.customer_id) {
        const response = await axios.get(`/customers/${me.customer_id}`);
        customerData = response.data;
      }

      dispatch({ type: 'ME_SUCCESS', payload: { user: me, customer: customerData } });
    } catch (err) {
      dispatch({
        type: 'ME_FAILED',
        payload: { error: err },
      });

      throw new Error(err);
    }
  };

  const setPassword = async (body) => {
    try {
      await axios.post('/auth/set-password', { ...body });
    } catch (err) {
      console.error('Error during setPassword:', err);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'auth0',
        user: state.user,
        login,
        logout: logoutHandler,
        setPassword,
        apiSettings,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
