import { PATHS } from 'routes/paths';
import SvgIconStyle from 'components/SvgIconStyle';

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  orders: getIcon('ic_invoice'),
  users: getIcon('ic_user'),
  customers: getIcon('ic_banking'),
  approvals: getIcon('ic_chat'),
  roles: getIcon('ic_role'),
  reconciliation: getIcon('ic_reconciliation'),
  banking: getIcon('ic_banking'),
  compliance: getIcon('ic_compliance'),
  transactions: getIcon('ic_bank_transfers'),
  hedge: getIcon('ic_finance'),
};

export const adminConfig = [
  {
    subheader: 'general',
    items: [
      { title: 'overview', path: PATHS.overview, icon: ICONS.dashboard, roles: ['admin'] },
      { title: 'customers', path: PATHS.customers.list, icon: ICONS.customers },
      { title: 'orders', path: PATHS.orders.list, icon: ICONS.orders },
    ],
  },
  {
    subheader: 'management',
    items: [
      { title: 'users', path: PATHS.users.list, icon: ICONS.users },
      { title: 'transactions', path: PATHS.transactions.list, icon: ICONS.transactions },
      { title: 'hedges', path: PATHS.hedges.list, icon: ICONS.hedge },
      { title: 'reconciliations', path: PATHS.reconciliation.list, icon: ICONS.reconciliation },
    ],
  },
];

export const customerConfig = () => [
  {
    subheader: 'general',
    items: [
      { isDemo: false, title: 'overview', path: PATHS.overview, icon: ICONS.dashboard },
      { isDemo: false, title: 'orders', path: PATHS.orders.list, icon: ICONS.orders },
    ],
  },
  {
    subheader: 'management',
    items: [
      { isDemo: false, title: 'users', path: PATHS.users.list, icon: ICONS.users },
      { title: 'transactions', path: PATHS.transactions.list, icon: ICONS.transactions },
      {
        isDemo: false,
        title: 'reconciliations',
        path: PATHS.reconciliation.list,
        icon: ICONS.reconciliation,
      },
    ],
  },
];
