import PropTypes from 'prop-types';
//
import ThemeColorPresets from './ThemeColorPresets';
import ThemeContrast from './ThemeContrast';
import ThemeLocalization from './ThemeLocalization';
import SettingsDrawer from './drawer';

ThemeSettings.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function ThemeSettings({ children }) {
  return (
    <ThemeColorPresets>
      <ThemeContrast>
        <ThemeLocalization>
          {children}
          <SettingsDrawer />
        </ThemeLocalization>
      </ThemeContrast>
    </ThemeColorPresets>
  );
}
