import LoadingScreen from 'components/LoadingScreen';
import { CustomersProvider } from 'contexts/CustomersContext';
import { OrdersProvider } from 'contexts/OrdersContext';
import { ReconciliationsProvider } from 'contexts/ReconciliationsContext';
import AuthGuard from 'guards/AuthGuard';
import GuestGuard from 'guards/GuestGuard';
import RoleBasedGuard from 'guards/RoleBasedGuard';
import useAuth from 'hooks/useAuth';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
import MainLayout from 'layouts/main';
import { Suspense, lazy } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export default function Router() {
  const { layout } = useAuth();

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'set-password',
          element: (
            <GuestGuard>
              <NewPassword />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),

      children: [
        { element: <Navigate to="/overview" replace />, index: true },
        { path: 'overview', element: <Overview /> },
        { path: 'orders', element: <OrderList /> },
        {
          path: 'orders',
          element: <OrdersProvider />,
          children: [
            { path: 'new', element: <NewOrder /> },
            { path: ':orderId', element: <OrderDetail /> },
          ],
        },
        {
          path: 'customers',
          children: [
            {
              path: '',
              element: <CustomerList />,
            },
            { path: 'new', element: <NewCustomer /> },
            {
              path: ':customerId',
              element: <CustomersProvider />,
              children: [
                { path: '', element: <Navigate to="info" replace /> },
                { path: 'info', element: <CustomerInfo /> },
                {
                  path: 'users',
                  children: [
                    { path: '', element: <CustomerUsers /> },
                    { path: 'new', element: <NewCustomerUser /> },
                  ],
                },
                {
                  path: 'transactions',
                  children: [
                    { path: '', element: <CustomerTransactions /> },
                    { path: 'new', element: <NewCustomerTransaction /> },
                  ],
                },
                {
                  path: 'fees',
                  children: [
                    { path: '', element: <CustomerFees /> },
                    { path: 'new', element: <NewFee /> },
                  ],
                },
                {
                  path: 'orders',
                  children: [
                    { path: '', element: <CustomerOrders /> },
                    { path: ':orderId', element: <OrderDetail /> },
                  ],
                },
                {
                  path: 'reconciliations',
                  children: [
                    { path: '', element: <CustomerDetailReconciliations /> },
                    {
                      path: ':reconciliationId',
                      element: <CustomerReconciliationDetail />,
                    },
                  ],
                },
                {
                  path: 'settings',
                  children: [
                    {
                      path: '',
                      element: <CustomerSettings />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: 'users',
          children: [
            { path: '', element: <UserList /> },
            { path: 'new', element: <NewUser /> },
          ],
        },
        {
          path: 'transactions',
          children: [
            { path: '', element: layout === 'admin' ? <TransactionCustomers /> : <TransactionList /> },
            { path: 'customers/:customerId', element: <TransactionList /> },
            { path: 'customers/:customerId/new', element: <NewTransaction /> },
            { path: ':transactionId', element: <TransactionDetail /> },
          ],
        },
        {
          path: 'hedges',
          children: [
            { path: '', element: <HedgeList /> },
            { path: ':orderId', element: <HedgeDetail /> },
            { path: 'new', element: <NewHedge /> },
          ],
        },
        {
          path: 'reconciliations',
          children: [
            { path: '', element: layout === 'admin' ? <ReconciliationCustomers /> : <ReconciliationsList /> },
            { path: 'customers/:customerId', element: <ReconciliationsList /> },
            {
              path: ':reconciliationId',
              element: <ReconciliationsProvider />,
              children: [
                { path: '', element: <ReconciliationDetail /> },
                { path: 'orders/:orderId', element: <ReconciliationOrderDetail /> },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'general',
      element: <Navigate to="/overview" replace />,
      index: true,
    },
    {
      path: 'management',
      element: <Navigate to="/users" replace />,
      index: true,
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));

// DASHBOARD OVERVIEW
const Overview = Loadable(lazy(() => import('pages/dashboard/Overview')));

// CUSTOMERS
const CustomerList = Loadable(lazy(() => import('pages/customers/lists')));
const NewCustomer = Loadable(lazy(() => import('pages/customers/forms/NewCustomer')));

// CUSTOMER DETAILS
const CustomerInfo = Loadable(lazy(() => import('pages/customers/details/Info')));
const CustomerUsers = Loadable(lazy(() => import('pages/customers/details/Users')));
const CustomerTransactions = Loadable(lazy(() => import('pages/customers/details/Transactions')));
const CustomerFees = Loadable(lazy(() => import('pages/customers/details/Fees')));
const CustomerOrders = Loadable(lazy(() => import('pages/customers/details/Orders')));
const CustomerDetailReconciliations = Loadable(lazy(() => import('pages/customers/details/Reconciliations')));
const CustomerReconciliationDetail = Loadable(lazy(() => import('pages/customers/details/ReconciliationDetail')));
const CustomerSettings = Loadable(lazy(() => import('pages/customers/details/Settings')));
const NewCustomerUser = Loadable(lazy(() => import('pages/customers/forms/NewCustomerUser')));
const NewCustomerTransaction = Loadable(lazy(() => import('pages/customers/forms/NewTransaction')));
const NewFee = Loadable(lazy(() => import('pages/customers/forms/NewFee')));

// ORDERS
const OrderList = Loadable(lazy(() => import('pages/orders/lists')));
const NewOrder = Loadable(lazy(() => import('pages/orders/forms/NewOrder')));
const OrderDetail = Loadable(lazy(() => import('pages/orders/details')));

// RECONCILIATIONS
const ReconciliationCustomers = Loadable(lazy(() => import('pages/reconciliations/lists/CustomersList')));
const ReconciliationsList = Loadable(lazy(() => import('pages/reconciliations/lists')));
const ReconciliationDetail = Loadable(lazy(() => import('pages/reconciliations/details/ReconciliationDetail')));
const ReconciliationOrderDetail = Loadable(lazy(() => import('pages/reconciliations/details/OrderDetail')));

// USERS
const UserList = Loadable(lazy(() => import('pages/users/lists')));
const NewUser = Loadable(lazy(() => import('pages/users/forms')));

// TRANSACTIONS
const TransactionList = Loadable(lazy(() => import('pages/transactions/lists')));
const TransactionCustomers = Loadable(lazy(() => import('pages/transactions/lists/CustomersList')));
const NewTransaction = Loadable(lazy(() => import('pages/transactions/forms')));
const TransactionDetail = Loadable(lazy(() => import('pages/transactions/details')));

// HEDGES
const HedgeList = Loadable(lazy(() => import('pages/hedges/lists')));
const HedgeDetail = Loadable(lazy(() => import('pages/hedges/details')));
const NewHedge = Loadable(lazy(() => import('pages/hedges/forms/NewHedge')));

// MAIN
const Page500 = Loadable(lazy(() => import('pages/main/Page500')));
const Page403 = Loadable(lazy(() => import('pages/main/Page403')));
const Page404 = Loadable(lazy(() => import('pages/main/Page404')));
