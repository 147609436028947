import { Box, Divider, Stack, Typography } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { capitalize } from 'lodash';

export default function TestModeBanner() {
  const { apiSettings: settings } = useAuth();

  if (!settings.test_mode) return null;

  return (
    <Box
      bgcolor="error.darker"
      sx={{
        width: '100%',
        height: '40px',
        zIndex: 9999,
        position: 'fixed',
        bottom: 0,
        borderTop: 1,
        borderColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        px: 2,
      }}
    >
      <Stack direction="row" spacing={2}>
        <Typography variant="body2" color="error.contrastText">
          Running in Test Mode.
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography variant="body2" color="error.contrastText">
          Worker: {capitalize(settings?.order_worker) || 'Unknown'}
        </Typography>
        <Divider orientation="vertical" flexItem />

        <Typography variant="body2" color="error.contrastText">
          Date: {settings?.current_date || 'Unknown'}
        </Typography>

        <Typography variant="body2" color="error.contrastText">
          Error Rate: {settings?.error_rate || '0'}%
        </Typography>
      </Stack>
    </Box>
  );
}
