export default function TextField() {
  return {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: '14px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
      },

      styleOverrides: {
        root: {
          '& .MuiAutocomplete-inputRoot': {
            padding: 0,
          },
        },
      },
    },
  };
}
