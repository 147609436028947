import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CardActionArea, FormControl, Grid, MenuItem, RadioGroup, Select, Stack, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FormProvider, RHFSwitch, RHFTextField } from 'components/hook-form';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import useSettings from '../../../hooks/useSettings';
import Iconify from '../../Iconify';
import BoxMask from './BoxMask';

const ORDER_WORKERS = [
  { code: 'kafka', label: 'Kafka' },
  { code: 'api', label: 'API' },
];

const BoxStyle = styled(CardActionArea)(({ theme }) => ({
  height: 72,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.disabled,
  border: `solid 1px ${theme.palette.grey[500_12]}`,
  borderRadius: Number(theme.shape.borderRadius) * 1.25,
}));

export default function SettingData() {
  const { themeData, onChangeData, onChangeDataType } = useSettings();

  const TestSettingSchema = Yup.object().shape({
    testMode: Yup.boolean().required(),
    currentDate: Yup.date().required(),
    orderWorker: Yup.string().required(),
  });

  const defaultValues = {
    testMode: themeData.testMode,
    currentDate: new Date().toISOString().split('T')[0],
    orderWorker: themeData.orderWorker,
  };

  const methods = useForm({
    resolver: yupResolver(TestSettingSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const watchTestMode = watch('testMode');

  useEffect(() => {
    onSubmit(values);
  }, [watchTestMode]);

  const onSubmit = (data) => {
    onChangeData({ type: themeData.type, ...data });
  };

  return (
    <RadioGroup name="themeData.type" value={themeData.type} onChange={onChangeDataType}>
      <Grid dir="ltr" container spacing={2.5}>
        {['real', 'mock'].map((type, index) => {
          const isSelected = themeData.type === type;

          return (
            <Grid key={type} item xs={6}>
              <Tooltip title={type === 'real' ? 'Real data' : 'Mock data'}>
                <BoxStyle
                  sx={{
                    ...(isSelected && {
                      color: 'primary.main',
                      boxShadow: (theme) => theme.customShadows.z20,
                    }),
                  }}
                >
                  <Iconify icon={index === 0 ? 'mdi:database' : 'ic:outline-data-object'} width={28} height={28} />
                  <BoxMask value={type} />
                </BoxStyle>
              </Tooltip>
            </Grid>
          );
        })}
        {themeData.type === 'mock' && (
          <Grid item xs={12}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={2}>
                <RHFTextField type="date" label="Current Date" name="currentDate" disabled={isSubmitting} fullWidth />
                <FormControl fullWidth margin="normal" size="small">
                  <Controller
                    name="orderWorker"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        {...field}
                        labelId="order-worker-label"
                        id="orderWorker"
                        value={field.value || ''}
                        onChange={(e) => field.onChange(e.target.value)}
                        error={!!methods.formState.errors.orderWorker}
                        displayEmpty
                        sx={{
                          '& .MuiSelect-select': {
                            color: field.value ? 'inherit' : '#adadad',
                          },
                        }}
                      >
                        <MenuItem value="" disabled color="warning">
                          Select order worker
                        </MenuItem>

                        {ORDER_WORKERS.map((order) => (
                          <MenuItem key={order.code} value={order.code} size="small">
                            {order.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
                <RHFSwitch edge="start" label="Test Mode" name="testMode" disabled={isSubmitting} />
                <Button fullWidth variant="outlined" type="submit" disabled={isSubmitting} sx={{ mt: 2 }}>
                  Run Recon Today
                </Button>
                <Button fullWidth variant="outlined" type="submit" disabled={isSubmitting} sx={{ mt: 2 }}>
                  Run Recon Pendings
                </Button>
              </Stack>
            </FormProvider>
          </Grid>
        )}
      </Grid>
    </RadioGroup>
  );
}
