import numeral from 'numeral';

export function fGoldPrice(number) {
  return new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    format: '0,0.00',
  }).format(number);
}

export function fCurrency(number) {
  return `${Intl.NumberFormat('tr', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number)} ₺`;
}

export function fGram(number) {
  return `${numeral(number).format('0.00a')} gr.`;
}

export function fPercent(number) {
  return numeral(number / 100).format('0.00%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}
