import { useState, useEffect, useCallback } from 'react';
import axios from 'utils/axios';
import useIsMountedRef from './useIsMountedRef';

const useApiSettings = () => {
  const [settings, setSettings] = useState({});

  const isMountedRef = useIsMountedRef();

  const get = useCallback(async () => {
    try {
      const response = await axios.get(`redis/`, {
        params: {
          key: 'api_settings',
        },
      });

      if (isMountedRef.current) {
        const value = JSON.parse(response.data.value);
        setSettings((prevSettings) => ({ ...prevSettings, ...value }));
      }
    } catch (err) {
      console.error(err);
    }
  }, [isMountedRef]);

  const set = useCallback(
    async (data) => {
      try {
        await axios.put('redis/', {
          key: 'api_settings',
          value: JSON.stringify(data),
        });

        if (isMountedRef.current) {
          await get();
        }
      } catch (err) {
        console.error(err);
      }
    },
    [isMountedRef, get]
  );

  useEffect(() => {
    get();
  }, [get]);

  return { settings, get, set };
};

export default useApiSettings;
